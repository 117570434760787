<template>
  <div class="users-container" v-if="dataLoaded">
    <a-row v-if="editingUser !== null" style="margin-bottom: 10px;">
      <user-edition-form
        :user="editingUser"
        @save="save"
        @cancel="editingUser = null"
      >
      </user-edition-form>
    </a-row>
    <a-row v-else style="marginBottom: 20px;">
      <a-row>
        <div class="table-gadgets">
          <a-col :span="2" :offset="2" v-if="isUserManager">
            <a-button
              :disabled="disableAddUser"
              class="btn btn-primary"
              type="primary"
              @click="addUser()"
            >
              {{ $t("form.add") }}
            </a-button>
          </a-col>
          <a-col :span="8" :offset="isUserManager ? 10 : 14">
            <a-input-search
              class="input-search-table"
              :placeholder="$t('common.search')"
              v-model="searchUser"
            />
          </a-col>
        </div>
      </a-row>
      <a-row type="flex" style="marginTop: 20px;">
        <a-col :span="21" :offset="1">
          <a-table
            class="usersList-table"
            :columns="columns"
            :dataSource="resultUserSearch"
            rowKey="id"
            :pagination="pagination"
            @change="onTableChange"
          >
            <span slot="roles" slot-scope="roles">
              <a-tag v-for="(role, index) in roles" :key="index">
                {{ role.name }}
              </a-tag>
            </span>
            <template
              v-if="isUserManager"
              slot="action"
              slot-scope="text, record"
            >
              <span v-if="editingUser === null">
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="edit" @click="edit(record)">
                      <a-icon type="edit" />{{ $t("form.edit") }}
                    </a-menu-item>
                    <a-menu-item key="reset">
                      <a-icon type="redo" />{{ $t("form.resetPassword") }}
                    </a-menu-item>
                    <a-menu-item key="delete" @click="confirmDelete(record)">
                      <a-icon type="delete" />{{
                        $t("form.delete")
                      }}</a-menu-item
                    >
                  </a-menu>
                  <a-button style="margin-left: 8px">
                    <a-icon type="more" />
                  </a-button>
                </a-dropdown>
              </span>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import UserEditionForm from "./UserEditionForm";
import { Row, Col, Table, Button, Dropdown, Modal, Tag } from "ant-design-vue";
import { isUserManager, userRoleGroup } from "../../utils/userAuth";

Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(Button);
Vue.use(Dropdown);
Vue.use(Modal);
Vue.use(Tag);

export default {
  name: "users-manager",
  components: {
    UserEditionForm,
  },
  computed: {
    isUserManager() {
      return isUserManager();
    },
    disableAddUser() {
      return this.editingUser !== null;
    },
    columns() {
      const columns = [
        {
          title: this.$t("users.firstName"),
          dataIndex: "firstName",
          key: "firstName",
          width: "15%",
          scopedSlots: { customRender: "firstName" },
        },
        {
          title: this.$t("users.lastName"),
          dataIndex: "lastName",
          key: "lastName",
          width: "15%",
          scopedSlots: { customRender: "lastName" },
        },
        {
          title: this.$t("users.roles"),
          dataIndex: "roles",
          key: "roles",
          width: "20%",
          scopedSlots: { customRender: "roles" },
        },
        {
          title: this.$t("users.email"),
          dataIndex: "email",
          key: "email",
          width: "18%",
          scopedSlots: { customRender: "email" },
        },
        {
          title: this.$t("users.phoneNumber"),
          dataIndex: "phoneNumber",
          key: "phoneNumber",
          width: "15%",
          scopedSlots: { customRender: "phoneNumber" },
        },
      ];
      if (this.isUserManager) {
        columns.push({
          dataIndex: "action",
          width: "5%",
          scopedSlots: { customRender: "action" },
        });
      }
      return columns;
    },
    dataLoaded() {
      return this.$store.state.users.lastFetch !== null;
    },
    resultUserSearch() {
      if (this.searchUser) {
        return this.users.filter((item) => {
          return this.searchUser
            .toLowerCase()
            .split("")
            .every(
              (v) =>
                item.firstName.toLowerCase().includes(v) ||
                item.lastName.toLowerCase().includes(v) ||
                item.email.toLowerCase().includes(v)
            );
        });
      }
      return this.users;
    },
  },
  mounted() {
    this.$store.dispatch("fetchUsers").then(() => {
      this.refreshUsersFromStore();
    });
  },
  data() {
    return {
      editingUser: null,
      pagination: {
        defaultCurrent: 1,
        current: 1,
      },
      searchUser: null,
      users: [],
    };
  },
  methods: {
    onTableChange(pagination) {
      this.pagination.current = pagination.current;
    },
    refreshUsersFromStore() {
      this.users = [...this.$store.state.users.users];
    },
    addUser() {
      this.editingUser = {
        id: "new",
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        role: "",
        sites: [],
      };
    },
    save(user) {
      if (this.editingUser) {
        let isNew = user.id === "new";
        let promise = null;
        delete user.confirmEmail;
        delete user.confirmPassword;

        if (isNew) {
          promise = this.$store.dispatch("addUser", user);
        } else {
          delete user.password;
          promise = this.$store.dispatch("updateUser", user);
        }

        promise.then(() => {
          this.editingUser = null;
          this.refreshUsersFromStore();
        });
      }
    },
    edit(user) {
      let target = { ...user };
      target.role = userRoleGroup(user.roles);
      target.confirmEmail = user.email;
      delete target.roles;
      this.editingUser = target;
    },
    confirmDelete(user) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store.dispatch("deleteUser", user);
        },
        onCancel: () => {},
      });
    },
  },
};
</script>
