<template>
  <div class="user-edition-container">
    <a-col :span="14" :offset="5">
      <a-card :title="$t('users.edition')">
        <a-row style="marginLeft: 60px; marginRight: -10px">
          <a-form-model
            layout="vertical"
            ref="addUserForm"
            :model="user"
            @submit="save"
            @submit.native.prevent
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.firstName')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('firstName')"
                >
                  <a-input
                    :placeholder="$t('users.firstName')"
                    v-model="form.firstName"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.lastName')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('lastName')"
                >
                  <a-input
                    :placeholder="$t('users.lastName')"
                    v-model="form.lastName"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.phoneNumber')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('phoneNumber')"
                >
                  <a-input
                    style="width: 100%"
                    :placeholder="$t('users.phoneNumber')"
                    v-model="form.phoneNumber"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.email')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('email')"
                >
                  <a-input
                    :placeholder="$t('users.email')"
                    v-model="form.email"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.confirmEmail')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('confirmEmail')"
                >
                  <a-input
                    :placeholder="$t('users.confirmEmail')"
                    v-model="form.confirmEmail"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-if="form.id === 'new'">
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.password')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('password')"
                >
                  <a-input-password
                    :placeholder="$t('users.password')"
                    v-model="form.password"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.confirmPassword')"
                  :label-col="formItemLayout.labelColPassword"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('confirmPassword')"
                >
                  <a-input-password
                    :placeholder="$t('users.confirmPassword')"
                    v-model="form.confirmPassword"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.roles')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :validate-status="getValidateStatus('role')"
                >
                  <a-radio-group button-style="solid" v-model="form.role">
                    <a-radio-button value="Admin">
                      {{ role.Admin }}
                    </a-radio-button>
                    <a-radio-button value="Integrator">
                      {{ role.Integrator }}
                    </a-radio-button>
                    <a-radio-button value="Telepilot">
                      {{ role.Telepilot }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('users.sites')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :validate-status="getValidateStatus('sites')"
                >
                  <a-select
                    mode="multiple"
                    v-model="form.sites"
                    :placeholder="$t('common.select')"
                    @change="onChange"
                  >
                    <a-select-option
                      v-for="site in sites"
                      :key="site.id"
                      :value="site.id"
                    >
                      {{ site.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item>
              <a-row>
                <a-col
                  :xs="{ span: 3, offset: 4 }"
                  :sm="{ span: 3, offset: 7 }"
                  :md="{ span: 3, offset: 8 }"
                  :xl="{ span: 3, offset: 8 }"
                  :xxl="{ span: 3, offset: 8 }"
                >
                  <a-button
                    @click="() => $emit('cancel')"
                    class="btn btn-danger"
                    type="danger"
                  >
                    {{ $t("form.cancel") }}
                  </a-button>
                </a-col>
                <a-col :xs="16" :sm="12" :md="8" :xl="8" :xxl="6">
                  <a-button @click="() => save()" class="btn" type="primary">
                    {{ $t("form.save") }}
                  </a-button>
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-form-model>
        </a-row>
      </a-card>
    </a-col>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Row,
  Col,
  Card,
  FormModel,
  Input,
  Button,
  Radio,
  Select,
} from "ant-design-vue";
import { RoleGroup } from "../../utils/userRoles";
import {
  required,
  email,
  minLength,
  sameAs,
  alpha
} from "vuelidate/lib/validators";

Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(Button);
Vue.use(Radio);
Vue.use(Select);

const passwordRegEx = RegExp(
  "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$"
);

export default {
  name: "user-edition-form",
  props: {
    user: Object,
  },
  mounted() {
    this.$store.dispatch("fetchSites").then(() => {
      this.refreshSitesFromStore();
    });
  },
  data() {
    return {
      form: {},
      formItemLayout: {
        labelCol: { span: 8 },
        labelColPassword: { span: 10 },
        wrapperCol: { span: 18 },
      },
      invalidFields: [],
      submitted: false,
      role: {
        Admin: RoleGroup.Admin,
        Telepilot: RoleGroup.Telepilot,
        Integrator: RoleGroup.Integrator,
      },
      sites: [],
    };
  },
  validations: {
    form: {
      firstName: { required, alpha },
      lastName: { required, alpha },
      phoneNumber: { required },
      role: { required },
      sites: { },
      email: { required, email },
      confirmEmail: { required, email, sameAsEmail: sameAs("email") },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    refreshSitesFromStore() {
      this.sites = [...this.$store.state.sites.sites];
    },
    getValidateStatus(field) {
      if (this.invalidFields.indexOf(field) === -1) {
        return "";
      } else {
        return "error";
      }
    },
    onChange(values) {
      this.form.sites = values;
    },
    save() {
      this.submitted = true;
      this.invalidFields = [];
      let isNew = this.form.id === "new";
      if (isNew) {
        let passwordMatch = passwordRegEx.test(this.form.password);
        if (!passwordMatch) {
          this.invalidFields.push("password");
        }
      } else {
        this.form.password = "password";
        this.form.confirmPassword = "password"
      }

      if (this.invalidFields.length === 0) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          if (
            !this.$v.form.firstName.required ||
            !this.$v.form.firstName.alpha
          ) {
            this.invalidFields.push("firstName");
          }
          if (!this.$v.form.lastName.required || !this.$v.form.lastName.alpha) {
            this.invalidFields.push("lastName");
          }
          if (!this.$v.form.phoneNumber.required) {
            this.invalidFields.push("phoneNumber");
          }
          if (!this.$v.form.role.required) {
            this.invalidFields.push("role");
          }
          if (!this.$v.form.sites.required) {
            this.invalidFields.push("sites");
          }
          if (isNew && 
            !this.$v.form.password.minLength ||
            !this.$v.form.password.required
          ) {
            this.invalidFields.push("password");
          }
          if (!this.$v.form.email.email || !this.$v.form.email.required) {
            this.invalidFields.push("email");
          }
          if (isNew && 
            !this.$v.form.confirmPassword.sameAsPassword ||
            !this.$v.form.confirmPassword.required
          ) {
            this.invalidFields.push("confirmPassword");
          }
          if (
            !this.$v.form.confirmEmail.sameAsEmail ||
            !this.$v.form.confirmEmail.required
          ) {
            this.invalidFields.push("confirmEmail");
          }
          this.submitted = false;
          return;
        }
        if (this.invalidFields.length === 0) {
          this.$emit("save", this.form);
        }
      }
    },
  },
  watch: {
    user: {
      immediate: true,
      handler: function(userFromProps) {
        if (userFromProps) {
          this.form = {
            ...this.form,
            ...userFromProps
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.user-edition-container {
  margin-top: 3.5%;
}

.btn-edition {
  margin-top: 2%;
}
</style>
